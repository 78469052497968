import { PulseLoader } from "react-spinners";
import styles from "../styles/loading-modal.module.css";

export const LoadingModal = ({ text }) => {
	return (
		<div className={styles.loadingModalContainer}>
			<div className={styles.loadingModal}>
				<p className={styles.loadingText}>{text}</p>
				<PulseLoader color="white" className={styles.loadingAnimation} />
			</div>
		</div>
	);
};
