import CheckIcon from "@mui/icons-material/Check";
import styles from "../styles/confirmation-modal.module.css";

export const ConfirmationModal = ({ text }) => {
	return (
		<div className={styles.confirmationModalContainer}>
			<div className={styles.confirmationModal}>
				<p className={styles.confirmationText}>{text}</p>
				<div className={styles.confirmationCheckContainer}>
					<CheckIcon sx={{ color: "white", fontSize: "3rem", margin: "auto" }}></CheckIcon>
				</div>
			</div>
		</div>
	);
};
