import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import { TransitionGroup } from "react-transition-group";
import FileUploadButton from "./FileUploadButton";
import { Collapse } from "@mui/material";
import { PTPException } from "../utils/models/ptpException";

export default function PharmacyCustomizer({ customNpis, setCustomNpis, handleDeleteClick, handleAddClick, errorText, setErrorText }) {
    const [checked, setChecked] = React.useState([0]);

    const [fileName, setFileName] = React.useState("");
    const [fileData, setFileData] = React.useState([]);

    const validateNpi = (index, value) => {
        let newErrorText = [...errorText];
        newErrorText[index] = value;
        setErrorText(newErrorText);
    };

    const handleCustomAddNpi = (name, npi) => () => {
        let newPharmacy = { npi: npi, pharmacyName: name };
        let newNpis = [...customNpis, newPharmacy];
        setCustomNpis(newNpis);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        try {
            if (file.name.slice(-3) != "csv") {
                throw new PTPException("Invalid File Type");
            }
            //reset just in case
            setFileData([]);
            if (file) {
                const reader = new FileReader();
                setFileName(file.name);

                reader.onload = (fileEvent) => {
                    const contents = fileEvent.target.result;

                    const lines = contents.split("\n");
                    const data = [];

                    // Assuming CSV has headers and each row represents an item
                    const headers = lines[0].split(",");
                    try {
                        if (!headers.includes("Name") && !headers.includes("NPI")) {
                            throw new PTPException("Invalid File Headers need, 'Name','NPI'");
                        }

                        for (let i = 1; i < lines.length; i++) {
                            const values = lines[i].split(",");
                            const item = {};

                            for (let j = 0; j < headers.length; j++) {
                                item[headers[j].replace(/(\r)/, "")] = values[j]?.replace(/(\r)/, "") || "";
                            }
                            if (item["NPI"].length === 0) {
                                continue;
                            }
                            data.push(item);
                        }

                        let newNpis = [...customNpis];
                        if (newNpis.length > 0 && newNpis[0].npi === "") {
                            newNpis = newNpis.slice(1, newNpis.length);
                        }
                        data.forEach((item, index) => {
                            // handleCustomAddNpi(item["Name"], item["NPI"]);
                            let newPharmacy = { npi: item["NPI"], pharmacyName: item["Name"] };
                            if (!/^\d*$/.test(item["NPI"])) {
                                console.log("invalid npi at " + item["NPI"]);
                                throw new PTPException(`Invalid NPI in file : ${item["NPI"]}`);
                            }
                            newNpis = [...newNpis, newPharmacy];
                            validateNpi(index, "");
                            setCustomNpis(newNpis);
                        });
                    } catch (err) {
                        console.log(err);
                        alert(err.name === "PTPException" ? err.message : "Unknown Error with File Upload");
                        return;
                    }

                    setFileData(data);
                };

                reader.readAsText(file);
            }
        } catch (err) {
            console.log(err);
            alert(err.name === "PTPException" ? err.message : "Unknown Error with File Upload");
        }
    };

    return (
        <Grid sx={{ bgcolor: "background.paper", minWidth: 380, borderRadius: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4" sx={{ paddingLeft: 2, paddingTop: 2, fontFamily: "Raleway, sans-serif" }}>
                    Add Pharmacies
                </Typography>
                {/* 
                <IconButton
                    color="secondary"
                    aria-label="upload a file"
                    onClick={(e) => {
                        console.log(e);
                    }}
                    sx={{ marginRight: 1, marginTop: 1 }}
                >
                    <FileUploadOutlinedIcon sx={{ width: 24, height: 24 }} />
                </IconButton> */}
                <FileUploadButton
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                        handleFileChange(e);
                    }}
                    filetext={fileName}
                />
            </Box>
            <List aria-labelledby="Add Pharmacies" sx={{ width: "100%", maxWidth: 440 }}>
                <TransitionGroup>
                    {customNpis.map((value, index) => {
                        return (
                            <Collapse key={index}>
                                <ListItem key={index}>
                                    {/* <CustomPharmacyInput value={value} index={index} customNpis={customNpis} setCustomNpis={setCustomNpis} onChange={} ></CustomPharmacyInput> */}

                                    <Grid>
                                        <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
                                            <TextField
                                                label={`NPI ${index + 1}`}
                                                error={
                                                    typeof errorText[index] === "undefined" || errorText[index].length === 0 || index >= errorText.length
                                                        ? false
                                                        : true
                                                }
                                                variant="outlined"
                                                value={value.npi}
                                                helperText={errorText?.[index] || ""}
                                                onChange={(e) => {
                                                    // handleNpiChange(e.target.value, index); this didnt work but this does... inteersting
                                                    console.log(/^\d*$/.test(e.target.value));
                                                    if (/^\d*$/.test(e.target.value)) {
                                                        validateNpi(index, "");
                                                        let holderNpis = [...customNpis];
                                                        holderNpis[index].npi = e.target.value;
                                                        setCustomNpis(holderNpis);
                                                    } else {
                                                        validateNpi(index, `${e.target.value} is not a number`);
                                                        // setErrorText(`Numbers only`);
                                                    }
                                                }}
                                            />

                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <TextField
                                                label={`Name ${index + 1}`}
                                                variant="outlined"
                                                value={value.pharmacyName}
                                                onChange={(e) => {
                                                    // handleNpiChange(e.target.value, index); this didnt work but this does... inteersting
                                                    let holderNpis = [...customNpis];
                                                    holderNpis[index].pharmacyName = e.target.value;
                                                    setCustomNpis(holderNpis);
                                                }}
                                            />
                                        </Paper>
                                    </Grid>

                                    <IconButton
                                        color="secondary"
                                        aria-label="delete this pharmacy"
                                        value={customNpis[index].pharmacyName}
                                        onClick={handleDeleteClick(index)}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </ListItem>
                            </Collapse>
                        );
                    })}
                </TransitionGroup>
                <Button size="small" color="secondary" sx={{ marginLeft: 2, marginTop: 2, fontFamily: "Raleway, sans-serif" }} onClick={handleAddClick()}>
                    + Add Pharmacy
                </Button>
            </List>
        </Grid>
    );
}
