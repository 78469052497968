import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from "../styles/dropdown-menu.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";

export const DropdownMenu = ({ defaultMessage, menuOptions, reduxState, reduxStateChangeFunc }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        setAnchorEl(null);
    };

    return (
        <div className={styles.dropdownMenu}>
            <button
                type="button"
                className={styles.dropdownMenuButton}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                id="basic-button"
            >
                <p className={styles.dropdownMenuButtonText}>{reduxState || defaultMessage}</p>
                <KeyboardArrowDownIcon sx={{ position: "absolute", right: "1rem", color: "white" }} />
            </button>
            <StyledEngineProvider injectFirst>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                        /* margin: auto; */
                    }}
                    sx={{
                        "& .MuiMenu-paper": {
                            backgroundColor: "rgba(0, 0, 0)",
                            border: "1px solid white",
                            width: "21.8rem",
                            color: "white",
                        },
                        "& .MuiMenuItem-root:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            fontSize: "2.2rem",
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                        },
                    }}
                    disableScrollLock={true}
                    className={styles.menu}
                >
                    {menuOptions.map((menuOption) => {
                        return (
                            <MenuItem
                                key={menuOption}
                                onClick={() => {
                                    dispatch(reduxStateChangeFunc(menuOption));
                                    setAnchorEl(null);
                                }}
                                className={styles.dropdownMenuItem}
                                divider={true}
                            >
                                <p className={styles.dropdownMenuItemText}>{menuOption}</p>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </StyledEngineProvider>
        </div>
    );
};
