import logo from "../images/logo.jpg";
import { useNavigate } from "react-router-dom";
import styles from "../styles/header.module.css";

export const Header = () => {
	const navigate = useNavigate();

	return (
		<header className={styles.header}>
			<img src={logo} alt="RISRx Logo" className={styles.logo} onClick={() => navigate("/")}></img>
		</header>
	);
};
