import Cookies from "universal-cookie";
import { useState, useMemo } from "react";
import { LoadingModal } from "../components/LoadingModal.jsx";
import { isValidCallId } from "../utils/scripts/inputValidations.js";
import { PTPException } from "../utils/models/ptpException.js";
import simpleFormStyles from "../styles/simple-form.module.css";
import resultLogStyles from "../styles/result-log.module.css";
import "react-calendar/dist/Calendar.css";
import "../styles/react-date-picker.css";

export default function CaremarkBpsIvrFullTranscriptions() {
	const cookies = useMemo(() => new Cookies(), []);
	const [callId, setCallId] = useState("");
	const [loading, setLoading] = useState(false);
	const [transcription, setTranscription] = useState("");

	const startFullCallTranscription = async () => {
		try {
			setLoading(true);
			setTranscription(() => "");

			const body = { callId: callId };

			const formCheckError = isValidCallId(body);
			if (formCheckError) throw new PTPException(formCheckError);

			const tranascriptionStartResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/start-caremark-full-transcription`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
			});

			if (!tranascriptionStartResponse.ok) {
				console.log(`Failure with call ID ${JSON.stringify(body)}`);
				throw new PTPException("Could not get transcription at this time");
			}

			const stopPingingTranscriptionTime = new Date(new Date().getTime() + 15 * 60000);
			const interval = setInterval(() => {
				getFullCallTranscription(interval, stopPingingTranscriptionTime);
			}, 20000);
		} catch (err) {
			console.log(err);
			alert(err.name === "PTPException" ? err.message : "Could not get transcription at this time");
			setLoading(false);
		}
	};

	const getFullCallTranscription = async (interval, stopPingingTranscriptionTime) => {
		try {
			if (new Date() > stopPingingTranscriptionTime) {
				clearInterval(interval);
				setLoading(false);
				return;
			}

			const body = { callId: callId };

			const formCheckError = isValidCallId(body);
			if (formCheckError) throw new PTPException(formCheckError);

			const transcriptionResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-caremark-full-transcription`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
			});

			if (!transcriptionResponse.ok) throw new PTPException("Could not get transcription at this time");

			const transcriptionData = await transcriptionResponse.json();
			if (transcriptionData.transcription) {
				setTranscription(() => transcriptionData.transcription);
				clearInterval(interval);
				setLoading(false);
			}
		} catch (err) {
			console.log(err);
			alert(err.name === "PTPException" ? err.message : "Could not get transcription at this time");
			setLoading(false);
		}
	};

	return (
		<div className="centered-container">
			{loading === true && <LoadingModal text="Transcription in progress..." />}
			<div className="program">
				<form className={simpleFormStyles.simpleForm}>
					<div className={simpleFormStyles.simpleFormInputContainer}>
						<label className={simpleFormStyles.simpleFormLabel}>Call ID:</label>
						<input
							type="text"
							className={simpleFormStyles.simpleFormInput}
							onChange={(e) => {
								e.target.value = e.target.value.replace(/[^0-9a-zA-Z]+/, "");
								setCallId(() => e.target.value);
							}}
						></input>
					</div>
					<div className={simpleFormStyles.simpleFormSubmitContainer}>
						<button type="button" className="run" onClick={startFullCallTranscription}>
							Get Transcription
						</button>
					</div>
				</form>
				<div className={resultLogStyles.resultLogContainer}>
					<div className={resultLogStyles.resultLog}>
						<p className={resultLogStyles.resultLogText}>{transcription}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
