import Cookies from "universal-cookie";
import { useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { LoadingModal } from "./LoadingModal.jsx";
import { ConfirmationModal } from "./ConfirmationModal.jsx";
import { isValidAaaRefreshFile } from "../utils/scripts/inputValidations.js";
import { PTPException } from "../utils/models/ptpException.js";
import simpleFormStyles from "../styles/simple-form.module.css";
import { Button } from "@mui/material";

export default function AAAFileRefresh() {
    const cookies = useMemo(() => new Cookies(), []);
    const programName = useSelector((state) => state.program.name);
    const [file, setFile] = useState(null);
    const inputFileRef = useRef();
    const [uploadLoading, setUploadLoading] = useState(false);
    const [confirmationModalActive, setConfirmationModalActive] = useState(false);

    const uploadToS3 = async (e) => {
        try {
            setUploadLoading(() => true);

            const aaaRefreshFileUploadErrorCheck = isValidAaaRefreshFile(file, programName);
            if (aaaRefreshFileUploadErrorCheck) throw new PTPException(aaaRefreshFileUploadErrorCheck);

            const urlResponse = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/aaa-s3-upload-refresh-program-file?filename=${file.name}&program=${programName}`,
                {
                    method: "GET",
                    redirect: "follow",
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
                }
            );

            const urlData = await urlResponse.json();

            if (!urlResponse.ok) {
                if (urlResponse.status === 400) throw new PTPException(urlData.message);
                else if (urlResponse.status === 500) throw new PTPException("File could not be uploaded at this time1");
            }

            const uploadUrl = urlData.url;

            const uploadResponse = await fetch(uploadUrl, {
                method: "PUT",
                body: file,
            });
            if (!uploadResponse.ok) throw new PTPException("File could not be uploaded at this time2");

            const refreshResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-refresh-program-file`, {
                body: JSON.stringify({
                    filename: file.name,
                    program: programName,
                }),
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
            });
            console.log(refreshResponse);
            if (!refreshResponse.ok) throw new PTPException("File could not be uploaded at this time");

            displayConfirmationModal();
        } catch (err) {
            console.log(err);
            alert(err.name === "PTPException" ? err.message : "File could not be uploaded at this time4");
        } finally {
            inputFileRef.current.value = "";
            setFile(() => null);
            setUploadLoading(() => false);
        }
    };

    const handleTruePaid = async (e) => {
        const truePaidResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/aaa-refresh-true-paid-process-batch`, {
            // body: JSON.stringify({
            // 	filename: file.name,
            // 	program: programName,
            // }),
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
        });
        if (!truePaidResponse.ok) throw new PTPException("FAILURE");
    };

    const displayConfirmationModal = () => {
        setConfirmationModalActive(() => true);

        setTimeout(() => {
            setConfirmationModalActive(() => false);
        }, 2500);
    };

    return (
        <div className="centered-container">
            {uploadLoading === true && <LoadingModal text="Uploading file..." />}
            {confirmationModalActive && <ConfirmationModal text="File successfully refreshed"></ConfirmationModal>}
            <div className="program">
                <form className={simpleFormStyles.simpleForm}>
                    <div className={simpleFormStyles.simpleFormInputContainer}>
                        <label className={simpleFormStyles.simpleFormLabel}>File:</label>
                        <input
                            className={simpleFormStyles.simpleFormFileInput}
                            type="file"
                            name="file"
                            accept=".txt,.csv,.xlsx"
                            onChange={(e) => {
                                setFile(() => e.target.files[0]);
                            }}
                            ref={inputFileRef}
                        ></input>
                    </div>
                    <div className={simpleFormStyles.simpleFormSubmitContainer}>
                        <button type="button" className="run" onClick={uploadToS3}>
                            Upload
                        </button>
                    </div>
                    {/* True Paid Refresh Button just in case automation doesn't work */}
                    {/* <Button variant="contained" onClick={handleTruePaid}>
                        True Paid Batch Button
                    </Button> */}
                </form>
            </div>
        </div>
    );
}
