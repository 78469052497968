import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "& .MuiFormControlLabel-root": {
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #black",
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(1),
        fontSize: 16,
        borderRadius: "4px",

        transition: "background 0.37s",
        "&:hover": {
            backgroundColor: theme.palette.action.selected,
            transition: "background 0.29s",
        },
    },

    "& .MuiFormLabel-root": {
        color: "black",
        fontWeight: "bold",
        marginLeft: "2rem",
        fontSize: 12,
        zIndex: 0,
        textAlign: "left",
        transition: "color 0.37s",
        "&.Mui-focused": {
            color: theme.palette.secondary.main,
            transition: "color 0.29s",
        },
    },

    "& .MuiCheckbox-root": {
        color: theme.palette.secondary.main,
        "&.Mui-checked": {
            color: theme.palette.secondary.main,
        },
    },
}));

export default function NDCCheckBox({ ndcs, handleSelectedNdcs }) {
    let ndcArray = Object.entries(ndcs);

    if (ndcArray.length <= 1) {
        return [];
    }

    return (
        <div>
            <StyledFormControl
                sx={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "100%",
                    maxWidth: 440,
                    paddingLeft: "2rem",
                }}
                component="fieldset"
                variant="standard"
            >
                <FormLabel component="legend">NDC Filters</FormLabel>
                <FormGroup>
                    {ndcArray.map((ndc, index) => {
                        return (
                            <FormControlLabel
                                control={<Checkbox onChange={handleSelectedNdcs} value={ndc[0]} name={ndc[1]} />}
                                key={index}
                                label={ndc[0] + " : " + ndc[1]}
                            />
                        );
                    })}
                </FormGroup>
            </StyledFormControl>
        </div>
    );
}
