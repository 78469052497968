import Cookies from "universal-cookie";
import DownloadIcon from "@mui/icons-material/Download";
import xlsx from "xlsx-js-style";
import CaremarkBpsIvrIndividual from "./CaremarkBpsIvrIndividual.jsx";

import { useState, useEffect, useCallback, useMemo } from "react";
import { LoadingModal } from "../components/LoadingModal.jsx";
import { PulseLoader } from "react-spinners";
import resultLogStyles from "../styles/result-log.module.css";

export default function CaremarkBpsIvr() {
  const cookies = useMemo(() => new Cookies(), []);
  const [callLog, setCallLog] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [refreshingResults, setRefreshingResults] = useState(false);

  const updateCallLog = useCallback(
    async (controller) => {
      try {
        setRefreshingResults(() => true);

        const requestParams = {
          method: "GET",
          redirect: "follow",
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${cookies.get("_auth")}` },
        };
        if (controller) requestParams.signal = controller.signal;

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ivr-transcriptions?processor=caremark&ivrType=bps`, requestParams);

        const data = await response.json();
        setCallLog(() => (response.ok ? data : []));
      } catch (err) {
        setCallLog(() => []);
        if (err.name === "AbortError") return;
        console.log(err);
        alert(err.name === "PTPException" ? err.message : "Failed to retrieve results");
      } finally {
        setRefreshingResults(() => false);
      }
    },
    [cookies]
  );

  useEffect(() => {
    const controller = new AbortController();

    updateCallLog(controller);
    return () => controller.abort();
  }, [updateCallLog]);

  useEffect(() => {
    const controller = new AbortController();
    const interval = setInterval(async () => {
      await updateCallLog(controller);
    }, 60000);

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [updateCallLog]);

  const downloadResults = (e) => {
    try {
      setDownloadLoading(() => true);
      const wb = xlsx.utils.book_new();
      const rows = getPrettyCallLog();
      xlsx.utils.book_append_sheet(wb, xlsx.utils.aoa_to_sheet(rows), "Call Log");
      xlsx.writeFile(wb, "Caremark_Accumulator_IVR_Results.xlsx");
    } catch (err) {
      console.log(err);
      alert("File could not be downloaded at this time");
    } finally {
      setDownloadLoading(() => false);
    }
  };

  const getPrettyCallLog = () => {
    const rows = [["Twilio Call ID", "ID", "Date of Birth", "Transcription"]];
    for (const call of callLog) rows.push([call.call_id, call.id, call.date_of_birth, call.transcription]);
    return rows;
  };

  return (
    <div className="centered-container">
      {downloadLoading === true && <LoadingModal text="Downloading file..." />}
      <div className="program">
        <CaremarkBpsIvrIndividual updateCallLog={updateCallLog} cookies={cookies}></CaremarkBpsIvrIndividual>

        <div className={resultLogStyles.resultLogContainer}>
          <div className={resultLogStyles.resultLogHeader}>
            {refreshingResults ? (
              <PulseLoader
                color="white"
                size={10}
                cssOverride={{ color: "white", marginLeft: "auto", marginRight: "0.5rem", "&:hover": { cursor: "pointer" } }}
              ></PulseLoader>
            ) : (
              <DownloadIcon
                fontSize="large"
                sx={{ color: "white", marginLeft: "auto", marginRight: "0.5rem", "&:hover": { cursor: "pointer" } }}
                onClick={downloadResults}
              ></DownloadIcon>
            )}
          </div>
          <div className={resultLogStyles.resultLog}>
            {callLog
              .sort((call1, call2) => {
                return new Date(call2.timestamp) - new Date(call1.timestamp);
              })
              .map((call) => (
                <div className={resultLogStyles.resultLogEntry} key={call.call_id}>
                  <p className={resultLogStyles.resultLogText}>Twilio Call SID: {call.call_id}</p>
                  <p className={resultLogStyles.resultLogText}>ID: {call.id}</p>
                  <p className={resultLogStyles.resultLogText}>Date of Birth: {call.date_of_birth}</p>
                  <p className={resultLogStyles.resultLogText}>Transcription: {call.transcription}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
