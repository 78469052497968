import { createSlice } from "@reduxjs/toolkit";

export const ivrProcessorSlice = createSlice({
	name: "ivrProcessor",
	initialState: {
		name: "",
	},

	reducers: {
		changeIvrProcessor: (state, action) => {
			state.name = action.payload;
		},
	},
});

export const { changeIvrProcessor } = ivrProcessorSlice.actions;
export default ivrProcessorSlice.reducer;
